html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, font, img,
ins, kbd, q, s, samp, small, strike,
strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
center, u, b, i {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: normal;
    font-style: normal;
    font-family: inherit;
    vertical-align: baseline
}
body{
    line-height: 1;
    background: #f5f5f5;
}
:focus {
    outline: 0
}
ol, ul{
    list-style: none
}
table{
    border-collapse: collapse;
    border-spacing: 0
}
blockquote:before, blockquote:after, q:before, q:after {
    content: ""
}
blockquote, q {
    quotes: ""
}
input, textarea {
    margin: 0;
    padding: 0
}
hr {
    margin: 0;
    padding: 0;
    border: 0;
    color: #000;
    background-color: #000;
    height: 1px
}
.router-link-exact-active{
  p{
    color: red!important;
  }
}
