@import './mixins/libs/thin.scss';

/* Text Color */

$hd:2 !default; // 基本单位.

$color-nav-base:#444444 !default; // 导航颜色
$color-text-base:#333333 !default; // 文字颜色
$color-nav-arrow:#999999 !default;  // 导航箭头
$color-warn:#C52125 !default;
$color-bg-color:#ffffff;
$color-bg-home-color:#F3F3F3;

/* Font */
$font-size-xs: 10px * $hd !default; // 非常用字号, 用于标签.
$font-size-sm: 12px * $hd !default; // 用于辅助信息.
$font-size-base: 14px * $hd !default; // 常用字号.
$font-size-lg: 16px * $hd !default; // 常规标题
$font-size-xl: 18px * $hd !default; // 大标题.
$font-size-xxl: 20px * $hd !default; // 用于大号的数字.
$font-family:PingFangSC-Regular;
$font-weight:400;

/* Color Palette*/
$color-grey-0: #333 !default;
$color-grey-1: #666 !default;
$color-grey-2: #999 !default;
$color-grey-3: #CCC !default;
$color-grey-4: #E5E5E5 !default;
$color-grey-5: #F0F0F0 !default;
$color-grey-6: #7A7A7A !default;
$color-grey-7: #666666 !default;

/* 边框颜色 */
$color-border-base: #F6F6F6 !default;
$color-border-split: tint($color-border-base, 20%) !default; // 分割线
$color-border-light: tint($color-border-base, 30%) !default;
$color-border-lighter: tint($color-border-base, 50%) !default;
$color-border-lightest: tint($color-border-base, 80%) !default;


/* Border Radius */
$border-radius-sm:2px * $hd !default;
$border-radius-md:4px * $hd !default;
$border-radius-lg:6px * $hd !default;
$border-radius-circle: 50% !default;


/* Checkbox */
$art-checkbox-circle-size: 40px !default;
$art-checkbox-icon-color:$color-warn;
$art-checkbox-icon-color-checked:$color-bg-color;
$art-checkbox-title-color:$color-text-base;
$art-checkbox-title-font-size:$font-size-lg !default;
$art-checkobx-desc-color:$color-grey-2 !default;

@mixin subtitle {
    font-size:13px;
    font-family:MicrosoftYaHei;
    font-weight:400;
}